<template>
  <div class="unsaved-changes-dialog">
    <McDialog
      ref="unsavedChangesDialog"
      submit-button-text="Continue Editing"
      submit-button-class="continue-editing"
      cancel-button-text="Discard Changes"
      cancel-button-class="discard-changes"
      @submit="onContinueEditing"
      @cancel="onDiscardChanges"
    >
      <template #header>
        <section class="title-wrapper">
          <span class="icon-triangle-exclamation-warning-alert"></span>
          <h3 class="title">
            You have unsaved changes
          </h3>
        </section>
      </template>

      <div class="warning-message">
        {{ warningMessage }}
      </div>

    </McDialog>
  </div>
</template>

<script lang="ts" setup>
import { onMounted, ref, toRef, watch } from "vue";
import McDialog from "@/components/ui/McDialog/McDialog.vue";
import { useUnsavedChangesStore } from "@/store/unsavedChangesStore";

const showDialog = ref(false);
const unsavedChangesDialog = ref();
const unsavedChangesStore = useUnsavedChangesStore();
const warningMessage = toRef(unsavedChangesStore.warningMessage);
const emit = defineEmits(['discard-changes', 'continue-editing']);

const onContinueEditing = () => {
  unsavedChangesStore.handleDialogActions(true);
  showDialog.value = false;
};

const onDiscardChanges = () => {
  unsavedChangesStore.handleDialogActions(false);
  showDialog.value = false;
};

onMounted(() => {
  unsavedChangesStore.$onAction(({ name })=> {
    if (name === "showUnsavedChangesDialog") {
      warningMessage.value = unsavedChangesStore.warningMessage;
      showDialog.value = true;
    }
  });
});

watch(showDialog, (newValue) => {
  newValue ? unsavedChangesDialog.value.openDialog() : unsavedChangesDialog.value.closeDialog();
});
</script>

<style scoped lang="scss">
.title-wrapper {
  display: flex;
  gap: 18px;
  align-items: center;

  .icon-triangle-exclamation-warning-alert {
    color: var(--background-color-danger-solid-light);
    font-size: 28px;
  }

  .title {
    color: var(--text-color-neutral-light);
    font-size: 21px;
    font-weight: 400;
    line-height: 21px;
    margin: 0;
  }
}

.warning-message {
  margin-top: 16px;
  color: var(--text-color-neutral-subtle-light);
}

:deep(.discard-changes) {
  --button-main-color: var(--background-color-danger-light);
  --button-text-color: var(--text-color-danger-subtle-light);
}

:deep(.continue-editing) {
  --button-main-color: var(--background-color-success-solid-light);
  --button-text-color: var(--text-color-contrast-light);
}
</style>